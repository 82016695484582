<template>
  <PrimaryErrorBanner />
  <div
    class="q-pa-lg column justify-center"
    v-bind="$attrs"
  >
    <GraphQLQueryScanField
      :query="storageQuery"
      :hint="t('Scan Storage')"
      :placeholder="t('Storage')"
      :rules="[containerMustBeEmptyRule]"
      :not-found-message="t('Storage not found')"
      @scan="handleScanStorage($event)"
    />
    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow v-slot="{ buttonProps }">
        <KeyboardToggleButton v-bind="buttonProps" />
        <QBtn
          v-bind="buttonProps"
          :icon="'mdi-close-circle-outline'"
          @click="cancel"
        >
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          v-if="store.supply"
          v-bind="buttonProps"
          icon="mdi-format-list-checks"
          @click="emit('show-supply')"
        >
          {{ t('Task') }}
        </QBtn>
      </ButtonsRow>
    </Teleport>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useOmniInput from '@/composables/useOmniInput';
import useSpeaker from '@/composables/useSpeaker';
import StorageForArrival from '@/graphql/fragments/StorageForArrival';
import type { Container, Storage } from '@/graphql/types';
import isContainer from '@/helpers/isContainer';
import useProductArrivalStore from '@/stores/productArrival';
import type { FunctionValidationRule } from '@/types';
import { gql } from '@urql/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, primaryError, PrimaryErrorBanner } = useErrorHandling();

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'show-supply'): void;
}>();

const store = useProductArrivalStore();

const speaker = useSpeaker();

const storageQuery = gql`
  query GetStorageByBarcodeForArrival($barcode: String!) {
    storageByBarcode(barcode: $barcode) { ...StorageForArrival }
  }
  ${ StorageForArrival }
`;

function handleScanStorage(storage: Storage) {
  speaker.speak(t('To {name}', { name: storage.name }));
  store.setStorageOptimistic(storage);
}

const { KeyboardToggleButton }  = useOmniInput({ skip: true });

function containerMustBeEmptyRule(storage: Container): ReturnType<FunctionValidationRule> {
  if (!isContainer(storage)) {
    return true;
  }
  if (storage.activeSelections.length > 0 || !storage.isEmpty) {
    return t('Container is occupied. Start {operation} in empty Container', {
      operation: t('Arrival'),
    });
  }
  return true;
}

const canceling = ref(false);

async function cancel() {
  if (!store.currentArrival) {
    emit('back');
    return;
  }

  canceling.value = true;

  const error = await store.deleteArrival();

  canceling.value = false;

  if (error) {
    fillErrorsFromGraphQLError(error);
    speaker.speak(primaryError.value);
  } else {
    emit('back');
  }
}

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>

<i18n lang="yaml">
ru:
  Arrival: Приёмка

en:
  Arrival: Arrival

</i18n>
