<template>
  <FilterableTable
    ref="itemsTable"
    :fields="itemsHeaders"
    :rows="task.items"
    :summary-row="summaryRow"
    :available-filters="availableFilters"
    :custom-filter="customFilter"
    :item-meets-search-string="null"
    :storage-prefix="'transferTaskCard.' + task.id"
    show-line-numbers
    shrink
    no-loading-columns
  >
    <template #body-cell-sku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
        variant="link"
      />
    </template>
    <template #body-cell-repacking="column">
      <QTd :props="column">
        <template v-if="column.value">
          {{ column.value[0] }}
          <QIcon name="mdi-arrow-right" />
          {{ column.value[1] }}
        </template>
      </QTd>
    </template>
    <template #body-cell-storageFrom="column">
      <QTd v-if="isNil(column.value)"> — </QTd>
      <BodyCellLink
        v-else
        :column="column"
        :to="
          column.row.storageFrom.__typename === 'Cell'
            ? { name: ROUTES.CELLS_EDIT, params: { id: column.row.storageFrom.id } }
            : { name: ROUTES.CONTAINERS_EDIT, params: { id: column.row.storageFrom.id } }
        "
        variant="link"
      />
    </template>
    <template #body-cell-storageTo="column">
      <QTd v-if="isNil(column.value)"> — </QTd>
      <BodyCellLink
        v-else
        :column="column"
        :to="
          column.row.storageTo.__typename === 'Cell'
            ? { name: ROUTES.CELLS_EDIT, params: { id: column.row.storageTo.id } }
            : { name: ROUTES.CONTAINERS_EDIT, params: { id: column.row.storageTo.id } }
        "
        variant="link"
      />
    </template>
  </FilterableTable>
</template>

<script setup lang="ts">
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FilterableTable from '@/components/FilterableTable.vue';
import type {
  ProductPack,
  ReportFilterInput,
  TransferTask,
  TransferTaskItem,
} from '@/graphql/types';
import getPacksRatio from '@/helpers/getPacksRatio';
import { unitAmount } from '@/helpers/itemAmount';
import { createBoolean } from '@/helpers/reports';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import { isNil } from 'ramda';
import * as R from 'ramda';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { task } = defineProps<{
  task: TransferTask;
}>();

function packAmount(amount: number, productPack: ProductPack) {
  if (!productPack.smallerProductPack) {
    return null;
  }
  const amountInBasUnits = productPack.quantityInMinMeasurementUnits;
  return t('{amount} of {multiplier} ({amountInBaseUnits})', {
    amount: unitAmount(amount, productPack.measurementUnit),
    multiplier: unitAmount(amountInBasUnits, productPack.minMeasurementUnit),
    amountInBaseUnits: unitAmount(amount * amountInBasUnits, productPack.minMeasurementUnit),
  });
}

const summaryRow = computed<TransferTaskItem | null>(
  () =>
    // eslint-disable-next-line unicorn/no-array-reduce
    task.items.reduce(
      (res, item) => ({
        ...item,
        plannedAmount:
          (res?.plannedAmount ?? 0)
          + (item.plannedAmount ?? 0) * item.productPack.quantityInMinMeasurementUnits,
        transferredAmount:
          (res?.transferredAmount ?? 0)
          + (item.transferredAmount ?? 0) * item.productPack.quantityInMinMeasurementUnits,
        availableAmount:
          (res?.availableAmount ?? 0)
          + (item.availableAmount ?? 0) * item.productPack.quantityInMinMeasurementUnits,
      }),
      null as TransferTaskItem | null,
    ) ?? null,
);

function itemAmountField(
  getAmount: (item: TransferTaskItem) => number,
): (item: TransferTaskItem) => string {
  return item => {
    const amount = getAmount(item);
    if (amount === 0) {
      return '';
    }
    return unitAmount(
      amount,
      item.productPack.smallerProductPack ? item.productPack.measurementUnit : null,
    );
  };
}

const itemsHeaders: TableColumn<TransferTaskItem>[] = [
  {
    label: t('SKU'),
    name: 'sku',
    field: i => i.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name: 'productPack.product.name',
    field: i => i.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Origin Cell/Container'),
    name: 'storageFrom',
    field: i => i.storageFrom.name,
    align: 'left',
  },
  {
    label: t('Target Cell/Container'),
    name: 'storageTo',
    field: i => i.storageTo?.name,
    align: 'left',
  },
  {
    label: t('Amount'),
    name: 'itemAmount',
    field: itemAmountField(i => i.plannedAmount),
    title: i => packAmount(i.plannedAmount, i.productPack),
    summaryField: true,
  },
  {
    label: t('Transferred'),
    name: 'transferredAmount',
    field: itemAmountField(i => i.transferredAmount),
    title: i => packAmount(i.transferredAmount, i.productPack),
    summaryField: true,
  },
  {
    label: t('Not Transferred'),
    name: 'notTransferredAmount',
    field: itemAmountField(i => i.plannedAmount - i.transferredAmount),
    title: i => packAmount(i.plannedAmount - i.transferredAmount, i.productPack),
    summaryField: true,
  },
  {
    label: t('Available Amount'),
    name: 'availableAmount',
    field: itemAmountField(i => R.min(i.availableAmount, i.plannedAmount - i.transferredAmount)),
    title: i =>
      packAmount(R.min(i.availableAmount, i.plannedAmount - i.transferredAmount), i.productPack),
    summaryField: true,
  },
  {
    label: t('Repacking'),
    name: 'repacking',
    field: i => {
      if (!i.targetProductPack) {
        return null;
      }
      const ratio = getPacksRatio(i.productPack, i.targetProductPack)!;
      return [
        `${i.plannedAmount} ${i.productPack.measurementUnit.shortName}`,
        `${(i.plannedAmount * ratio[1]) / ratio[0]} ${i.targetProductPack.measurementUnit.shortName}`,
      ];
    },
  },
];

const availableFilters: ReportFilter[] = [
  {
    field: 'availableAmount',
    label: t('Available Amount'),
    operators: [createBoolean('=')],
  },
];

function customFilter(filter: ReportFilterInput, item: TransferTaskItem) {
  if (filter.field === 'availableAmount') {
    const amountLeft = item.plannedAmount - item.transferredAmount;
    if (amountLeft === 0) {
      return false;
    }

    const value = JSON.parse(filter.value) as boolean;
    const allProductAvailable = item.availableAmount >= amountLeft;

    return value ? allProductAvailable : !allProductAvailable;
  }
  return null;
}

const itemsTable = ref();
</script>

<i18n lang="yaml">
ru:
  Available Amount: Доступный остаток
  '{amount} of {multiplier} ({amountInBaseUnits})': >
    {amount} из {multiplier} ({amountInBaseUnits})
en:
  Available Amount: Available Amount
  '{amount} of {multiplier} ({amountInBaseUnits})': >
    {amount} of {multiplier} ({amountInBaseUnits})
</i18n>
