<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="transfer">
        <CardTitle>
          {{
            t('Transfer {id} dated {date}', {
              id: id,
              date: formatDate(transfer.createdAt, FORMATS.DATETIME),
            })
          }}
          <QChip
            :color="badgeColors.forOperationState(transfer)"
            text-color="black"
          >
            {{ t(`transferState.${transfer.state}`) }}
          </QChip>
        </CardTitle>

        <QCardSection>
          <div class="row q-col-gutter-sm">
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <QField
                :label="t('User')"
                stack-label
              >
                <template #control>
                  {{ userFullName(transfer.user) }}
                </template>
              </QField>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <QField
                :label="t('Task')"
                stack-label
              >
                <template #control>
                  <RouterLink
                    v-if="transfer.task"
                    :to="{ name: ROUTES.TRANSFER_TASKS_EDIT, params: { id: transfer.task.id } }"
                  >
                    {{ transfer.task.id }}
                    <template v-if="transfer.task.kind">
                      ({{ t('transferTaskKind.' + transfer.task.kind) }})
                    </template>
                  </RouterLink>
                  <template v-else>
                    {{ t('Not specified') }}
                  </template>
                </template>
              </QField>
            </div>
            <div
              v-if="storageFrom"
              class="col-12 col-sm-3 col-md-3 col-lg-2"
            >
              <StorageField
                :label="t('Source')"
                :storage="storageFrom"
              />
            </div>
            <div
              v-if="transfer.task"
              class="col-12 col-sm-3"
            >
              <QInput
                readonly
                :label="t('Volume (cm³)')"
                :model-value="transfer.volume"
              />
            </div>
          </div>
        </QCardSection>

        <TransferItems :transfer="transfer" />

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="navigateBack({ name: ROUTES.TRANSFERS_LIST })"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />

          <ConfirmsAction
            v-if="transfer.task && transfer?.state === TransferStateEnum.CREATED"
            @confirmed="completeTask"
          >
            <template #activator="{prompt}">
              <QBtn
                :loading="loading"
                icon="mdi-check-all"
                @click="prompt()"
              >
                {{ t('Execute') }}
              </QBtn>
            </template>
          </ConfirmsAction>

          <ConfirmsAction
            v-if="transfer?.state !== TransferStateEnum.COMPLETED"
            :confirm-text="t('Yes')"
            :cancel-text="t('No')"
            @confirmed="cancelTransfer"
          >
            <template #title>
              {{ t('Cancel Transfer?') }}
            </template>
            <template #activator="{ prompt }">
              <QBtn
                v-bind="buttonProps"
                icon="mdi-close-circle-outline"
                @click="prompt"
              >
                {{ t('Cancel') }}
              </QBtn>
            </template>
          </ConfirmsAction>

          <QBtn
            v-if="canDelete"
            :loading="loading"
            :label="t('Delete')"
            icon="mdi-delete"
            color="red"
            @click="deleteTransfer"
          />
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Transfer not found') }}
        </p>
        <QBtn
          exact
          color="primary"
          icon="mdi-arrow-left"
          :to="{ name: ROUTES.TRANSFERS_LIST }"
        >
          {{ t('Go to Transfers List') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Cell, Container, Transfer , MutationDeleteOperationArgs } from '@/graphql/types';
import { TransferStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import TransferItems from '@/views/Storage/TransferItems.vue';
import { gql, useClientHandle, useQuery } from '@urql/vue';
import { QField, QIcon } from 'quasar';
import { computed, h, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as R from 'ramda';
import navigateBack from '@/helpers/navigateBack';
import ConfirmsAction from '@/components/ConfirmsAction.vue';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { client: urql } = useClientHandle();

const { fillErrorsFromGraphQLError, clearErrors, getPrimaryError } = useErrorHandling();

const props = defineProps<{
  id: string;
}>();

const loading = ref(false);

const transferFragment = gql`
  fragment TransferForCard on Transfer {
    id
    createdAt
    state
    task { id kind }
    user { id firstname lastname }
    items {
      id
      volume
      transferredAmount
      plannedAmount
      storageFrom { id name }
      storageTo { id name }
      storageUnit {
        id
        productPack {
          id
          deletedAt
          quantity
          product {
            id
            sku
            name
          }
          measurementUnit { id name shortName }
        }
      }
    }
    volume
    movements {
      id
      amount
      amountBeforeFrom
      amountBeforeTo
      storable {
        id
        productPack {
          id
          quantity
          quantityInMinMeasurementUnits
          product { id sku name }
          measurementUnit { id name shortName }
          deletedAt
        }
      }
      storageFrom { id name storageCell { name } }
      storageTo   { id name storageCell { name } }
    }
    childOperations {
      __typename
      id
      movements {
        id
        amount
        storable { id productPack { id measurementUnit { id shortName } } }
        storageFrom { id }
        storageTo { id }
        complementaryMovement { id }
      }
    }
  }
`;

const { data, error, fetching } = useQuery<{ transfer: Transfer }>({
  query: gql`
    ${transferFragment}
    query GetTransferForCard($id: ID!) { transfer(id: $id) {
     ...TransferForCard
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);

const transfer = ref<Transfer | null>();
watch(data, data => {
  transfer.value =  data.transfer;
});

const storageFrom = computed(() => {
  if (transfer.value?.task) {
    return null;
  }

  const storagesFrom = R.uniq(transfer.value?.movements.map(m => m.storageFrom) ?? []);

  return storagesFrom!.length === 1 ? storagesFrom![0] : null;
});

// noinspection LocalVariableNamingConventionJS
const StorageField = ({ storage, label }: { storage: Cell | Container | null; label: string }) => {
  return h(QField, {
    label,
    stackLabel: true,
  }, {
    control: () => [
      storage?.__typename === 'Container' ? h(QIcon, {
        left: true,
        name: 'mdi-select-group',
      }) : null,
      storage?.name,
      ...(storage?.__typename === 'Container'
        ? [' (', storage.storageCell.name, ')']
        : [])
    ],
  });
};

const canDelete = computed(() => transfer.value?.state === TransferStateEnum.CREATED);

async function deleteTransfer(): Promise<void> {
  await urql.mutation<unknown, MutationDeleteOperationArgs>(
    gql`mutation DeleteTransferInCard($transferId: ID!) { deleteTransfer(transferId: $transferId) }`,
    { transferId: props.id },
  );

  navigateBack({ name: ROUTES.TRANSFERS_LIST });
}

async function cancelTransfer() {
  clearErrors();

  const { error } = await urql.mutation<{ transfer: Transfer }>(
    gql`
      mutation CancelTransfer($id: ID!) {
        cancelTransfer(transferId: $id)
      }
    `,
    { id: props.id },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  await navigateBack({ name: ROUTES.TRANSFERS_LIST });
}

async function completeTask() {
  loading.value = true;
  const { data } = await urql.mutation<{ transfer: Transfer }>(
    gql`
      ${transferFragment}
      mutation ExecuteTransferTask($id: ID!) {
        transfer: executeTransferTask(transferId: $id) {
          ...TransferForCard
        }
      }
    `,
    { id: transfer.value.id },
  );

  if (!error) {
    transfer.value = data!.transfer;
  }
  loading.value = false;
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>

<i18n lang="yaml">
ru:
  Execute: Выполнить
  Transfer not found: Перемещение не найдено
  Go to Transfers List: К списку перемещений
  Transfer {id} dated {date}: Перемещение {id} от {date}
  Source: Исходная ячейка
  Destination: Конечная ячейка
  Volume (cm³): Объем (см³)
  Transfer in not "Created" state cannot be deleted: Нельзя удалить перемещение не в статусе "Создан"

en:
  Execute: Execute
  Transfer not found: Transfer not found
  Go to Transfers List: Go to Transfers List
  Transfer {id} dated {date}: Transfer {id} dated {date}
  Source: Source
  Destination: Destination
  Volume (cm³): Volume (cm³)
  Transfer in not "Created" state cannot be deleted: Transfer in not "Created" state cannot be deleted
</i18n>
