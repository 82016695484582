<template>
  <CardTitle>
    {{ title }}
  </CardTitle>
  <BaseTable
    :rows="data?.productShipments ?? []"
    :summary-row="summaryRow"
    :fields="tableFields"
    :loading="fetching"
    :storage-prefix="storagePrefix"
    without-search
  >
    <template #body-cell-shipment="column">
      <BodyCellLink
        :column="column"
        :to="shipmentCardRoute(column.value)"
        variant="link"
      >
        {{ column.value.externalId }}
      </BodyCellLink>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/components/BaseTable.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import CardTitle from '@/components/CardTitle.vue';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type {
  Product,
  QueryProductShipmentsArgs,
  ShipmentDataForProduct,
  CustomerOrder,
  SupplierReturn,
} from '@/graphql/types';
import shipmentCardRoute from '@/helpers/shipmentCardRoute';
import type { TableColumn } from '@/types/reports';
import { gql, useQuery } from '@urql/vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  product: Product;
  type: CustomerOrder['__typename'] | SupplierReturn['__typename'];
  title: string;
  storagePrefix: string;
}>();

const tableFields: TableColumn<ShipmentDataForProduct>[] = [
  {
    label: props.type === 'SupplierReturn' ? t('Return') : t('Order'),
    name: 'shipment',
    field: 'shipment',
  },
  {
    label: t('Created at'),
    name: 'createdAt',
    field: row => row.shipment.createdAt,
    format: date => formatDate(date),
  },
  {
    label: t('Planned Shipment Date'),
    name: 'plannedShipmentDate',
    field: row => row.shipment.plannedShipmentDate,
    format: date => formatDate(date),
  },
  {
    label: props.type === 'SupplierReturn' ? t('Supplier') : t('Customer'),
    name: 'counterparty',
    field: row => row.shipment.counterparty?.name,
  },
  {
    label: t('Carrier Name'),
    name: 'carrier',
    field: row => row.shipment.carrier?.name ?? '',
  },
  {
    label: t('State'),
    name: 'state',
    field: row => row.shipment.state,
    format: state => t(`shipmentState.${state}`),
  },
  {
    label: t('Amount'),
    name: 'itemAmount',
    field: i =>
      i.shipmentItem.amount * i.shipmentItem.productPack.quantityInMinMeasurementUnits || '',
    summaryField: true,
  },
  {
    label: t('Selected'),
    name: 'selectedAmount',
    field: i => (i.shipmentItem.selectedAmount ?? 0) - (i.shipmentItem.shippedAmount ?? 0) || '',
    summaryField: true,
  },
  {
    label: t('Reserved'),
    name: 'reservedAmount',
    field: i =>
      (i.shipmentItem.reservedAmount ?? 0) - (i.shipmentItem.amountInSelections ?? 0) || '',
    summaryField: true,
  },
  {
    label: t('Shipped'),
    name: 'shippedAmount',
    field: i => i.shipmentItem.shippedAmount || '',
    summaryField: true,
  },
  {
    label: t('Not Reserved'),
    name: 'notReservedAmount',
    field: i =>
      i.shipmentItem.amount * i.shipmentItem.productPack.quantityInMinMeasurementUnits
        - (i.shipmentItem.reservedAmount ?? 0) || '',
    summaryField: true,
    headerClasses: 'text-no-wrap',
  },
  {
    label: t('Priority'),
    name: 'priority',
    field: i => i.shipment.priority,
  },
];

const { data, fetching } = useQuery<
  { productShipments: ShipmentDataForProduct[] },
  QueryProductShipmentsArgs
>({
  query: gql`
    query GetProductOrdersData($productId: ID!, $type: String!) {
      productShipments(productId: $productId, type: $type) {
        shipment {
          __typename
          id
          externalId
          createdAt
          plannedShipmentDate
          counterparty {
            id
            name
          }
          carrier {
            id
            name
          }
          state
          priority
        }
        shipmentItem {
          id
          amount
          amountInSelections
          reservedAmount
          selectedAmount
          shippedAmount
          productPack {
            id
            quantityInMinMeasurementUnits
            product {
              id
              mostBasicProductPack {
                id
                smallerProductPack {
                  id
                }
                minMeasurementUnit {
                  id
                  shortName
                }
                measurementUnit {
                  id
                  shortName
                }
                quantityInMinMeasurementUnits
              }
            }
          }
        }
      }
    }
  `,
  variables: computed(() => ({
    productId: props.product.id,
    type: props.type,
  })),
});

const summaryRow = computed<ShipmentDataForProduct | null>(
  () =>
    // eslint-disable-next-line unicorn/no-array-reduce
    data.value?.productShipments.reduce(
      (res, { shipmentItem: si, shipment }) => ({
        shipment,
        shipmentItem: {
          ...si,
          amount: (res?.shipmentItem.amount ?? 0) + (si.amount ?? 0),
          reservedAmount: (res?.shipmentItem.reservedAmount ?? 0) + (si.reservedAmount ?? 0),
          amountInSelections:
            (res?.shipmentItem.amountInSelections ?? 0) + (si.amountInSelections ?? 0),
          selectedAmount: (res?.shipmentItem.selectedAmount ?? 0) + (si.selectedAmount ?? 0),
          shippedAmount: (res?.shipmentItem.shippedAmount ?? 0) + (si.shippedAmount ?? 0),
        },
      }),
      null as ShipmentDataForProduct | null,
    ) ?? null,
);
</script>
